import React from 'react'

function scalePercentageOn200Max(percentage) {
  //  the new 100% is 200% of the old 100% 
  return (percentage * 100) / 200
}

export default function PerformanceChart({ percentage }) {
  return (
    <div
      style={{
        borderRadius: 100,
        border: '1px solid #EDF2FF',
        boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.10)',
        padding: 5,
        height: 210,
        width: 50,
      }}
      className='mr-4'
    >
      <div
        className="position-relative"
        style={{
          height: 200,
          borderRadius: 100,
          width: 40,
          // background:
          // 'linear-gradient(#04A90A,#8DCA0A,#F9BC20,#E77D1C,#CA0A0A)',
        }}
      >
        <div
          className="position-absolute"
          style={{
            bottom: 0,
            height: 40,
            width: 40,
            background: '#CA0A0A',
            borderTop: '1px solid #000',
            borderBottomLeftRadius: 100,
            borderBottomRightRadius: 100,
          }}
        ></div>
        <div
          className="position-absolute"
          style={{
            bottom: 40,
            height: 40,
            width: 40,
            background: '#E77D1C',
            borderTop: '1px solid #000',
          }}
        ></div>
        <div
          className="position-absolute"
          style={{
            bottom: 80,
            height: 40,
            width: 40,
            background: '#F9BC20',
            borderTop: '1px solid #000',
          }}
        ></div>
        <div
          className="position-absolute"
          style={{
            bottom: 120,
            height: 40,
            width: 40,
            background: '#8DCA0A',
            borderTop: '1px solid #000',
          }}
        ></div>
        <div
          className="position-absolute"
          style={{
            bottom: 160,
            height: 40,
            width: 40,
            background: '#04A90A',
            borderTopLeftRadius: 100,
            borderTopRightRadius: 100,
          }}
        ></div>
        <div
          className="position-absolute"
          style={{
            // zIndex: 1,
            left: 50,
            top:
              percentage > 200
                ? 0
                : `calc(${100 - scalePercentageOn200Max(percentage)}% - 10px)`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="19"
            viewBox="0 0 49 19"
            fill="none"
          >

            <path
              d="M8 0H49V19H8L0 10L8 0Z"
              fill={
                percentage >= 0 &&
                percentage < 40
                  ? '#CA0A0A'
                  : percentage >= 40 &&
                    percentage < 80
                  ? '#E77D1C'
                  : percentage >= 80 &&
                    percentage < 120
                  ? '#F9BC20'
                  : percentage >= 120  &&
                    percentage < 160
                  ? '#8DCA0A'
                  : '#04A90A'
              }
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#fff"
              fontSize="12"
              fontWeight="bold"
            >
              {percentage}%
            </text>
          </svg>
        </div>
      </div>
    </div>
  )
}

import React, { useMemo, useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import {
  TotaliLavorazioniState,
  TotaliOperatoreState,
} from '../localstate/lavorazioniOrdineOperatore'
import Spinner from 'reactstrap/lib/Spinner'

import moment from 'moment'
import PerformanceChart from './PerformanceChart'
import { DATE_FORMAT } from '../dateUtils'
import useQueryParams from 'magik-react-hooks/useRouterQueryParams'
import ReactDatePicker from 'react-datepicker'

export default function PerformanceOperatori() {
  const [queryParams, setQueryParams] = useQueryParams()

  const initialFilters = useMemo(() => {
    return {
      from_date:
        queryParams.from_date || moment().startOf('month').format(DATE_FORMAT),
      to_date:
        queryParams.to_date || moment().endOf('month').format(DATE_FORMAT),
    }
  }, [queryParams])

  const [{ data, loading }] = useRunRj(TotaliOperatoreState, [initialFilters])
  const [{ data: lavorazioniTotals }] = useRunRj(TotaliLavorazioniState, [
    initialFilters,
  ])

  const dataOrdered = useMemo(() => {
    if (!data) return []

    const dataFormatted = data.results.map((item) => {
      const performance =
        item.tempo !== 0
          ? (
              (parseInt(moment.duration(item.tempo_teorico).as('minutes')) /
                parseInt(moment.duration(item.tempo).as('minutes'))) *
              100
            ).toFixed(0)
          : 0
      return {
        operator: item.operatore_first_name + ' ' + item.operatore_last_name,
        performance: performance,
        tempo_teorico: item.tempo_teorico,
        tempo: item.tempo,
      }
    })
    return dataFormatted.sort((a, b) => {
      return b.performance - a.performance
    })
  }, [data])

  const dataLavorazioni = useMemo(() => {
    if (!lavorazioniTotals) return []

    const dataFormatted = lavorazioniTotals.results.map((item) => {
      const performance =
        item.tempo !== 0
          ? (
              (parseInt(moment.duration(item.tempo_teorico).as('minutes')) /
                parseInt(moment.duration(item.tempo).as('minutes'))) *
              100
            ).toFixed(0)
          : 0
      return {
        nome_fase_pianificazione: item.nome_fase_pianificazione,
        performance: performance,
        tempo_teorico: item.tempo_teorico,
        tempo: item.tempo,
      }
    })
    return dataFormatted.sort((a, b) => {
      return b.performance - a.performance
    })
  }, [lavorazioniTotals])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {data === null && loading && (
            <div className="text-center mt-4">
              <Spinner color="primary" />
            </div>
          )}
          {data && (
            <>
              <div className="text-center d-flex justify-content-center text-primary mt-2"></div>

              <div className="filters mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-label">Filtra per data</div>
                    <div className="d-flex align-items-center">
                      <ReactDatePicker
                        selected={moment(initialFilters.from_date).toDate()}
                        onChange={(date) => {
                          setQueryParams({
                            from_date: moment(date).format(DATE_FORMAT),
                          })
                        }}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                      />
                      <div className="ml-2">
                        <ReactDatePicker
                          selected={moment(initialFilters.to_date).toDate()}
                          onChange={(date) => {
                            setQueryParams({
                              to_date: moment(date).format(DATE_FORMAT),
                            })
                          }}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                {dataLavorazioni.map((item) => (
                  <div
                    className="col-md-6 position-relative bg-light d-flex align-items-center justify-content-center mb-3"
                    key={item.nome_fase_pianificazione}
                  >
                    <PerformanceChart percentage={item.performance} />
                    <div className="ml-5  ps-4">
                      <h6 className="ps-5">Performance attività</h6>
                      <div className='text-uppercase'>{item.nome_fase_pianificazione}</div>
                      <div className="badge badge-primary p-2 mt-2">
                        Tempo teorico:{' '}
                        {parseInt(
                          moment.duration(item.tempo_teorico).as('minutes')
                        ) + ' min'}
                        {' ('}
                        {/* show numero gg numero hh numero mm */}
                        {moment
                          .duration(item.tempo_teorico, 'minutes')
                          .months()}
                        m{' '}
                        {moment.duration(item.tempo_teorico, 'minutes').days()}g{' '}
                        {moment.duration(item.tempo_teorico, 'minutes').hours()}
                        h{' '}
                        {moment
                          .duration(item.tempo_teorico, 'minutes')
                          .minutes()}
                        m{')'}
                      </div>
                      <br />
                      <div className="badge badge-primary mt-2 p-2">
                        Tempo:{' '}
                        {parseInt(moment.duration(item.tempo).as('minutes')) +
                          ' min'}
                        {' ('}
                        {/* show numero gg numero hh numero mm */}
                        {moment.duration(item.tempo, 'minutes').months()}m{' '}
                        {moment.duration(item.tempo, 'minutes').days()}g{' '}
                        {moment.duration(item.tempo, 'minutes').hours()}h{' '}
                        {moment.duration(item.tempo, 'minutes').minutes()}m{')'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row mt-3 mb-2">
                {dataOrdered.map((item) => (
                  <div
                    className="col-md-4 position-relative bg-light d-flex align-items-center justify-content-center mb-3"
                    key={item.operator}
                  >
                    <PerformanceChart percentage={item.performance} />
                    <div className="ml-5  ps-4">
                      <h6 className="ps-5">Performance attività</h6>
                      <div>{item.operator}</div>
                      <div className="badge badge-primary p-2 mt-2">
                        Tempo teorico:{' '}
                        {parseInt(
                          moment.duration(item.tempo_teorico).as('minutes')
                        ) + ' min'}
                        {' ('}
                        {/* show numero gg numero hh numero mm */}
                        {moment
                          .duration(item.tempo_teorico, 'minutes')
                          .months()}
                        m{' '}
                        {moment.duration(item.tempo_teorico, 'minutes').days()}g{' '}
                        {moment.duration(item.tempo_teorico, 'minutes').hours()}
                        h{' '}
                        {moment
                          .duration(item.tempo_teorico, 'minutes')
                          .minutes()}
                        m{')'}
                      </div>
                      <div className="badge badge-primary mt-2 p-2">
                        Tempo:{' '}
                        {parseInt(moment.duration(item.tempo).as('minutes')) +
                          ' min'}
                        {' ('}
                        {/* show numero gg numero hh numero mm */}
                        {moment.duration(item.tempo, 'minutes').months()}m{' '}
                        {moment.duration(item.tempo, 'minutes').days()}g{' '}
                        {moment.duration(item.tempo, 'minutes').hours()}h{' '}
                        {moment.duration(item.tempo, 'minutes').minutes()}m{')'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import rjDebounce from "react-rocketjump/plugins/debounce"
import qs from "query-string"
import { ajax } from "rxjs/ajax"
import { SETTINGS } from "../../../django"
import { rjListWithTotals } from "./common"

export const LavorazioniOrdineOperatoreAdminState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: "LavorazioniOrdineOperatoreAdminState",
    effect: (params = {}) =>
      ajax.getJSON(
        `/api/planner/lavorazioni-ordine-operatore/?${qs.stringify(params)}`
      ),
    computed: {
      lavorazioniOrdine: "getList",
      totals: 'getTotals',
      pagination: "getPagination",
      loading: "isLoading",
      error: "getError",
    },
  }
)


export const TotaliOperatoreState = rj({
  name: 'TotaliOperatoreState',
  effect: (filters) => ajax.getJSON(`/api/planner/lavorazioni-operatori-totals/?${qs.stringify(filters)}`),
})

export const TotaliLavorazioniState = rj({
  name: 'TotaliLavorazioniState',
  effect: (filters) => ajax.getJSON(`/api/planner/lavorazioni-nome-fase-pianificazione-totals/?${qs.stringify(filters)}`),
})
